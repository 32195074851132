<script
    lang="ts"
    setup
>
    import AppSheet from '~/components/app/sheet/AppSheet.vue'
    import AppModal from '~/components/app/modal/AppModal.vue'

    const { maxTablet } = useWindowSize()
</script>

<template>
    <component :is="maxTablet ? AppSheet : AppModal">
        <template
            v-for="(_, name) in $slots"
            #[name]="slotProps"
        >
            <slot
                :name="name"
                v-bind="slotProps"
            />
        </template>
    </component>
</template>
